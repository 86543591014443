<template>
  <div>
    <v-app>
      <v-main>
        <slot />
      </v-main>
    </v-app>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  mounted() {},
}
</script>
